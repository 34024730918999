<script setup>
import { ref } from "vue"
import { storeToRefs } from "pinia"
import { useCurrentSession } from "../store"
import { useEvents } from "@/app/stores/event"
import EventForm from "@/app/components/events/EventForm.vue"
import { Dialog } from "@/app/components"
import AdminEvent from "@/app/components/events/AdminEvent.vue"
import {
  initialState,
  EventUpdate
} from "@/app/components/events/blueprint-utilities"
import { PencilIcon, QrCodeIcon } from "@heroicons/vue/24/solid"
import VisualSlug from "@/app/components/VisualSlug.vue"

const { data, events } = storeToRefs(useCurrentSession())
const eventStore = useEvents()

// misc ui
const qrSlugOpen = ref(false)

// handling event create / update
const eventFormOpen = ref(false)
const eventUpdateData = ref(initialState())

function openFormAsNew(position) {
  eventUpdateData.value.position = position
  eventFormOpen.value = true
}

function openFormAsEdit(event) {
  eventUpdateData.value = { ...event }
  eventFormOpen.value = true
}

async function handleEventFormSubmit() {
  const update = new EventUpdate(eventUpdateData.value, null)
  await eventStore.create({ ...update, sessionId: data.value.id })
  eventFormOpen.value = false
}
</script>

<template>
  <div class="session">
    <h1 class="text-2xl text-center mt-2 mb-4">{{ data.name }}</h1>
    <div class="session__controls">
      <router-link :to="`/sessions/${data.slug}/edit`" class="btn">
        <PencilIcon class="w-4" />
        <span> Edit Session </span>
      </router-link>
      <!-- <button class="btn">
				<PencilIcon class="w-4"/> 
				Edit Session
			</button> -->
      <button class="btn" @click="qrSlugOpen = true">
        <QrCodeIcon class="w-6" />
        Show QR
      </button>
    </div>
  </div>
  <div class="events">
    <div class="outer__add">
      <button @click="openFormAsNew(1)">(+) Add Event</button>
    </div>

    <TransitionGroup name="events">
      <div class="outer" v-for="(event, i) in events" :key="i + 1">
        <div class="outer__event">
          <AdminEvent
            :event="event"
            :updateMethod="openFormAsEdit"
            :showUpdate="false"
          />
        </div>
        <div class="outer__add">
          <button @click="openFormAsNew(i + 2)">(+) Add Event</button>
        </div>
      </div>
    </TransitionGroup>

    <Dialog v-model:open="eventFormOpen">
      <EventForm @created="handleEventFormSubmit" v-model="eventUpdateData" />
    </Dialog>

    <VisualSlug v-model:open="qrSlugOpen" :qrSlug="data.qrSlug" />
  </div>
</template>

<style scoped>
.session__controls {
  @apply flex;

  .btn {
    @apply flex
			gap-2
			items-center;
  }
}

.outer {
  @apply mt-2;
}

.outer__add {
  @apply text-center
		mt-8
		border-t
		border-t-ci2-grey2
		border-dotted;

  button {
    @apply bg-ci2-black
			transform translate-y-[-0.9em]
			px-2
			hover:text-ci2-brand;
  }
}
</style>
