import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "fill-rule": "evenodd",
  "clip-rule": "evenodd",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M8 14.781c-.693-.041-1.295-.486-1.51-1.13-.54-1.619-.355-1.368-1.786-2.362A1.61 1.61 0 0 1 4 9.968q-.001-.256.085-.509c.553-1.611.554-1.3 0-2.92A1.5 1.5 0 0 1 4 6.032c0-.514.254-1.009.704-1.322 1.43-.992 1.245-.741 1.786-2.363.225-.675.878-1.131 1.615-1.131h.005c1.765.006 1.451.109 2.889-.903A1.74 1.74 0 0 1 12 0c.352 0 .703.104 1.001.313 1.428 1.004 1.12.909 2.889.903h.005c.737 0 1.39.456 1.616 1.131.54 1.619.351 1.368 1.786 2.363.449.312.703.808.703 1.321 0 .169-.026.342-.085.508-.552 1.612-.554 1.302 0 2.92q.087.254.085.509c0 .513-.254 1.009-.703 1.321-1.435.996-1.246.745-1.786 2.362A1.69 1.69 0 0 1 16 14.781V24l-3.958-3L8 24zM17.714 8A5.714 5.714 0 1 0 6.287 8a5.714 5.714 0 0 0 11.427 0M12 4C9.795 4 8 5.794 8 8s1.795 4 4 4 4-1.794 4-4-1.794-4-4-4"
    }, null, -1)
  ])))
}
export default { render: render }