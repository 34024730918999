import { defineStore } from "pinia"
import { reactiveComputed, useArrayFilter } from "@vueuse/core"
import { ref, computed, toValue } from "vue"
import { $api as $apiBase } from "@/app/services/api"
import { useCollection } from "@/app/composable/useCollection"
import { useAuth } from "@/app/stores/auth"
import { useCalls } from "@/app/stores/calls"
import { sessionChannelStore } from "@/app/stores/channel"
import { useCrud } from "@/app/composable/useCrud.js"
import { useChannel } from "~/app/composable/useChannel.js"

const useSessionState = sessionState => {
  return reactiveComputed(() => {
    const value = toValue(sessionState)

    return {
      sessionState: value,
      isRunning: ["live", "paused"].includes(value),
      isDraft: value === "draft",
      isEnded: value === "ended",
      isPaused: value === "paused"
    }
  })
}

export const useCurrentSession = defineStore("currentSession", () => {
  const data = ref({})
  const sessionId = computed(() => toValue(data).id)
  const sessionState = computed(() => toValue(data).state)

  const $api = $apiBase.apiV1Sessions

  const isLoading = computed(
    () => toValue($api.show.loading) || toValue($api.index.loading)
  )
  const eventStore = useSessionEvents()
  const callStore = useCalls()
  const channel = sessionChannelStore()

  const eventHistory = reactiveComputed(() => {
    return eventStore.history.map(event => {
      return {
        ...event,
        call: callStore.byEvent(event.id) || { wager: 1 }
      }
    })
  })

  const events = reactiveComputed(() => {
    return eventStore.action.map(event => {
      return {
        ...event,
        call: callStore.byEvent(event.id) || { wager: 1 }
      }
    })
  })

  const update = ({ session }) => {
    const dataVal = toValue(data)
    data.value = {
      ...dataVal,
      ...session
    }

    if (session.events) {
      eventStore.updateAll(session.events)
    }
  }

  const updateEvent = event => {
    eventStore.update(event)
  }

  const subscribe = async session => {
    channel.subscribe(session.gid)

    channel.on("event:hide", ({ event }) => updateEvent(event))
    channel.on("event:show", ({ event }) => updateEvent(event))
    channel.on("event:publish_event", ({ event }) => updateEvent(event))
    channel.on("event:close_event", ({ event }) => updateEvent(event))
    channel.on("event:resolve", ({ event }) => updateEvent(event))

    // TODO fix the semantics of the event names here
    channel.on("session:create", session => update(session))
    channel.on("session:update", session => update(session))
    channel.on("session:start", session => update(session))
    channel.on("session:pause", session => update(session))
    channel.on("session:resume", session => update(session))
    channel.on("session:stop", session => update(session))
  }

  const unsubscribe = () => {
    channel.unsubscribe()
  }

  const setCurrent = async slug => {
    return await $api.show({ params: { id: slug } }, async res => {
      const { session } = res

      data.value = session

      await subscribe(session)
      await eventStore.index(session.id)

      return session
    })
  }

  const startSession = async () => {
    await $api.start({ params: { id: sessionId.value } })
  }
  const pauseSession = async () => {
    await $api.pause({ params: { id: sessionId.value } })
  }
  const resumeSession = async () => {
    await $api.resume({ params: { id: sessionId.value } })
  }
  const stopSession = async () => {
    await $api.stop({ params: { id: sessionId.value } })
  }

  return {
    sessionId,
    setCurrent,
    subscribe,
    unsubscribe,
    data,
    events,
    eventHistory,
    channel,
    startSession,
    pauseSession,
    resumeSession,
    stopSession,
    isLoading,
    state: useSessionState(sessionState)
  }
})

export const useSessions = defineStore("sessions", () => {
  const { data, loading, error, index, create, show, update, collection } =
    useCrud($apiBase.apiV1Sessions, {
      name: "session"
    })

  const sessions = computed(() => {
    return toValue(data).map(session => {
      return {
        ...session,
        state: useSessionState(session.state)
      }
    })
  })

  const findBySlug = slug => {
    return collection.findBy(obj => obj.slug === slug)
  }

  const channel = useChannel()

  const subscribe = () => {
    channel.subscribe({ channel: "SessionsChannel", id: "all" })

    channel.on("session:collection_update", ({ session }) => {
      collection.update(session)
    })
  }

  const unsubscribe = () => {
    channel.unsubscribe()
  }

  return {
    index,
    findBySlug,
    create,
    show,
    update,
    data: sessions,
    loading,
    error,
    channel,
    subscribe,
    unsubscribe
  }
})

export const useSessionEvents = defineStore("session:events", () => {
  const { apiV1SessionsEvents } = $apiBase
  const auth = useAuth()
  const data = ref([])
  const { updateAll, update } = useCollection(data)

  // scoped computed props
  const actionStates = computed(() =>
    auth.isAdmin ? ["open", "draft", "closed"] : ["open", "closed"]
  )
  const historyStates = computed(() =>
    auth.isAdmin ? ["open", "draft"] : ["open"]
  )

  const action = useArrayFilter(data, e => actionStates.value.includes(e.state))
  const history = useArrayFilter(
    data,
    e => !historyStates.value.includes(e.state)
  )

  const index = async sessionId => {
    const { events } = await apiV1SessionsEvents.index({
      params: {
        sessionId
      }
    })

    updateAll(events)
    return events
  }

  return {
    updateAll,
    update,
    index,
    data,
    action,
    history
  }
})
